<template>
  <div class="app-body">
    <div class="flex_between">
      <bread-crumb></bread-crumb>
      <div class="pdtb10">
        <el-button type="danger" size="mini" class="mgr10" @click="revokeShow = true">
          &nbsp;&nbsp;删除&nbsp;&nbsp;
        </el-button>
        <el-button type="primary" size="mini" @click="handlerChange">
          &nbsp;&nbsp;编辑&nbsp;&nbsp;
        </el-button>
      </div>
    </div>
    <el-card class="el-main a-fs-14 projectadd">
      <div class="fnW600 font18 mgb24 pdb10 bordrb2">
        {{ detailData.userName }}
      </div>
      <div>
        <div class="flex_start mgb36">
          <div style="width: 30%">
            <div class="flex_start font14 mgb8">
              <div class="w72 fontC3">电话</div>
              <div class="fontC2">{{ detailData.mobile || "--" }}</div>
            </div>
            <div class="flex_start font14 mgb8">
              <div class="w72 fontC3">标签</div>
              <div class="fontC2">{{ detailData.remark || "--" }}</div>
            </div>
            <div class="flex_start font14 mgb8">
              <div class="w72 fontC3">所属商户</div>
              <div class="fontC2">{{ detailData.companyName || "--" }}</div>
            </div>
            <div class="flex_start font14 mgb8">
              <div class="w72 fontC3">所在部门</div>
              <div class="fontC2">{{ detailData.dep || "--" }}</div>
            </div>
          </div>
          <div>
            <div class="flex_start font14 mgb8">
              <div class="w72 fontC3">创建时间</div>
              <div class="fontC2">{{ detailData.createTimeText || "--" }}</div>
            </div>
            <div class="flex_start font14 mgb8">
              <div class="w72 fontC3">过期时间</div>
              <div class="fontC2">{{ detailData.disableTimeText || "--" }}</div>
            </div>
            <div class="flex_start font14 mgb8">
              <div class="w72 fontC3">账号状态</div>
              <div class="fontC2">
                {{ detailData.isValid == 1 ? "启用" : "禁用" }}
              </div>
            </div>
            <div class="flex_start font14 mgb20">
              <div class="w72 fontC3">登录平台</div>
              <div class="fontC2 flex_start">
                <div class="flex_start">
                  <i
                    :class="[
                      detailData.canLoginWeb == 1
                        ? 'el-icon-check fontC5'
                        : 'el-icon-close fontC13',
                    ]"
                  ></i>
                  <span class="mgl10">WEB端</span>
                </div>
                <div class="flex_start mgl32">
                  <i
                    :class="[
                      detailData.canLoginWxapp == 1
                        ? 'el-icon-check fontC5'
                        : 'el-icon-close fontC13',
                    ]"
                  ></i>
                  <span class="mgl10">微信小程序端</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="border2 mgb20">
          <div class="bgC8 pdtb10 pdl32">
            <span class="fontC2 font16">WEB端权限分配</span>
          </div>
          <div class="pdl32">
            <div class="flex_start flex_wrap">
              <div
                v-for="(item, index) in webList"
                :key="index"
                class="pdtb16 bordrb2 w16p flex_start"
              >
                <i
                  :class="[item.userHasThisRole ? 'el-icon-check fontC5' : 'el-icon-close fontC13']"
                ></i>
                <span class="mgl10">{{ item.roleName }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="border2">
          <div class="bgC8 pdtb10 pdl32">
            <span class="fontC2 font16">微信小程序端权限分配</span>
          </div>
          <div class="pdl32">
            <div class="flex_start flex_wrap">
              <div v-for="(item, index) in wxList" :key="index" class="pdtb16 bordrb2 w16p">
                <i
                  :class="[item.userHasThisRole ? 'el-icon-check fontC5' : 'el-icon-close fontC13']"
                ></i>
                <span class="mgl10">{{ item.roleName }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-card>
    <el-dialog title="提示" :visible.sync="revokeShow" width="30%">
      <div class="flex_start">
        <i class="fontC9 el-icon-info font18 mgr6"></i>
        <span class="fontC3 font14">确定删除该员工？</span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="revokeShow = false">取 消</el-button>
        <el-button type="primary" @click="cancelSignAgency">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "StaffDetail",

  data() {
    return {
      revokeShow: false,
      userId: "",
      companyId: "",
      webList: [],
      wxList: [],
      detailData: {},
    };
  },
  created() {
    if (this.$route.query.id) {
      this.id = parseInt(this.$route.query.id);
      this.userId = parseInt(this.$route.query.userId);
      this.companyId = parseInt(this.$route.query.companyId);
      this.getDetail();
    }
  },
  methods: {
    //获取详情
    getDetail() {
      this.$Axios
        ._get({
          url: this.$Config.apiUrl.getCompanyUserDetail,
          params: {
            userId: this.userId,
            companyId: this.companyId,
          },
        })
        .then(({ data }) => {
          this.detailData = data;
          this.getWebModuleUserRole();
          this.getWxAppModuleUserRole();
        });
    },
    //获取用户web权限
    getWebModuleUserRole() {
      this.$Axios
        ._get({
          url: this.$Config.apiUrl.getWebModuleUserRole,
          params: {
            userId: this.userId,
            companyId: this.companyId,
          },
        })
        .then(({ data }) => {
          this.webList = data;
        });
    },

    //获取用户wx权限
    getWxAppModuleUserRole() {
      this.$Axios
        ._get({
          url: this.$Config.apiUrl.getWxAppModuleUserRole,
          params: {
            userId: this.userId,
            companyId: this.companyId,
          },
        })
        .then(({ data }) => {
          this.wxList = data;
        });
    },
    //删除员工
    cancelSignAgency() {
      this.$Axios
        ._get({
          url: this.$Config.apiUrl.delCompanyUser,
          params: {
            userId: this.userId,
            companyId: this.companyId,
          },
        })
        .then(() => {
          this.$message.success("删除成功!");
          this.$store.dispatch("remove_current_view", this);
        });
    },
    //编辑员工
    handlerChange() {
      this.$router.push({
        path: `/staffManage/staff-exit?id=${this.id}&userId=${this.userId}&companyId=${this.companyId}`,
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
